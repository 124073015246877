// Attach your callback function to the `window` object
window.initMap = function() {
  // JS API is loaded and available

    var center = { 
        lat: 47.49915989710307, 
        lng: 9.740495713461296
    };
    const userLat = $('#searchform').attr('data-userlat');
    const userLng = $('#searchform').attr('data-userlng');
    if ((userLat && userLat != '') && (userLng && userLng != '')) {
        center = {
            lat: parseFloat(userLat),
            lng: parseFloat(userLng)
        };
    }

    map = new google.maps.Map(document.getElementById("map"), {
        zoom: 12,
        center: center,
        mapId: "f2e7eefcb9bcac06",
        // styles: [
        //     {
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#ebe3cd"
        //         }
        //       ]
        //     },
        //     {
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#523735"
        //         }
        //       ]
        //     },
        //     {
        //       "elementType": "labels.text.stroke",
        //       "stylers": [
        //         {
        //           "color": "#f5f1e6"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "administrative",
        //       "elementType": "geometry.stroke",
        //       "stylers": [
        //         {
        //           "color": "#c9b2a6"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "administrative.country",
        //       "stylers": [
        //         {
        //           "saturation": 65
        //         },
        //         {
        //           "lightness": -5
        //         },
        //         {
        //           "weight": 3.5
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "administrative.country",
        //       "elementType": "geometry.fill",
        //       "stylers": [
        //         {
        //           "weight": 4
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "administrative.land_parcel",
        //       "elementType": "geometry.stroke",
        //       "stylers": [
        //         {
        //           "color": "#dcd2be"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "administrative.land_parcel",
        //       "elementType": "labels",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "administrative.land_parcel",
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#ae9e90"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "landscape.natural",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#dfd2ae"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#dfd2ae"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi",
        //       "elementType": "labels.text",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi",
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#93817c"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi.business",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi.park",
        //       "elementType": "geometry.fill",
        //       "stylers": [
        //         {
        //           "color": "#a5b076"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi.park",
        //       "elementType": "labels.text",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "poi.park",
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#447530"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#f5f1e6"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.arterial",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.arterial",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#fdfcf8"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.highway",
        //       "stylers": [
        //         {
        //           "saturation": 60
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.highway",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#f8c967"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.highway",
        //       "elementType": "geometry.stroke",
        //       "stylers": [
        //         {
        //           "color": "#e9bc62"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.highway",
        //       "elementType": "labels",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.highway.controlled_access",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#e98d58"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.highway.controlled_access",
        //       "elementType": "geometry.stroke",
        //       "stylers": [
        //         {
        //           "color": "#db8555"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.local",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.local",
        //       "elementType": "labels",
        //       "stylers": [
        //         {
        //           "visibility": "off"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "road.local",
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#806b63"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "transit",
        //       "stylers": [
        //         {
        //           "weight": 3.5
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "transit.line",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#dfd2ae"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "transit.line",
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#8f7d77"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "transit.line",
        //       "elementType": "labels.text.stroke",
        //       "stylers": [
        //         {
        //           "color": "#ebe3cd"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "transit.station",
        //       "elementType": "geometry",
        //       "stylers": [
        //         {
        //           "color": "#dfd2ae"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "water",
        //       "elementType": "geometry.fill",
        //       "stylers": [
        //         {
        //           "color": "#b9d3c2"
        //         }
        //       ]
        //     },
        //     {
        //       "featureType": "water",
        //       "elementType": "labels.text.fill",
        //       "stylers": [
        //         {
        //           "color": "#92998d"
        //         }
        //       ]
        //     }
        //   ]          
    });
    
    deleteMarkers();
    const marker = new google.maps.Marker({
        position: center,
        map: map,
        icon: vars.images + '/PIN_sm.png',
    });

    const infowindow = new google.maps.InfoWindow({
      content: 'Dein Standort',
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });

    markers.push(marker);

    geocoder = new google.maps.Geocoder();


    //Set Markers initial
    $('.company').each(function() {
        const address = $(this).attr('data-street') + ' ' +  $(this).attr('data-zip') + ' ' +  $(this).attr('data-city');
        const infowindowContent = '<h3 class="infowindow-title">' + $(this).attr('data-title') + '</h3>' + 
          '<span class="infowindow-address">' + $(this).attr('data-street') + '<br/>' +  $(this).attr('data-zip') + ', ' +  $(this).attr('data-city') + '</span>' +
          '<a href="' + $(this).attr('data-link') + '" class="infowindow-link">Öffnen</a>';
        addressToMarker(address, infowindowContent);
    });
};

function addressToMarker(address, infowindowContent) {
    geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == 'OK') {
            // map.setCenter(results[0].geometry.location);

            const infowindow = new google.maps.InfoWindow({
              content: infowindowContent,
            });

            var marker = new google.maps.Marker({
                map: map,
                position: results[0].geometry.location,
                icon: vars.images + '/PIN_orange.png',
            });

            marker.addListener("click", () => {
              infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
              });
            });

            markers.push(marker);
        } else {
            alert('Geocode was not successful for the following reason: ' + status);
        }
    });
}

function setMapOnAll(map) {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

function deleteMarkers() {
    setMapOnAll(null);
    markers = [];
}

// Append the 'script' element to 'head'
document.head.appendChild(script);