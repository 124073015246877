var geocoder;
var map;
var plzLat;
var plzLng;

$(document).ready(function () {
    
    $('#searchCompany').on('click',function(){
        var jsonData = {};
        jsonData['text'] = $('#searchCompanyText').val();
        jsonData['location'] = $('#searchCompanyLocation').val();
        //Not needed because no input for branch for the moment
        //jsonData['branch'] = $('#searchCompanyBranch').val();
        restRequest($('#searchCompany'),jsonData);
    });

    function restRequest($obj,JsonData) {
        if(!$obj.length)
            return;

        var text = JsonData['text'];
        var location = JsonData['location'];

        //Not needed because no input for branch for the moment
        //var branch = JsonData['branch'];

        $.ajax({
            url: vars.searchCompany_rest,

            //Not needed because no input for branch for the moment
            //data:{ text: text, branch: branch, location: location },

            data:{ text: text, location: location },
            dataType: "json",
            method:'GET',
            success: function (response) {
                geoCodePLZ(location);

                //Delete Markers
                deleteMarkers();
                
                response['companies'].forEach(element => {
                    if (element.latLng.lat && element.latLng.lng) {
                        addressToMarker(parseFloat(element.latLng.lat), parseFloat(element.latLng.lng));
                    }
                });

                //Not needed because no input for branch for the moment
                //manipulateHtml(response['html'], text, branch, location, response['companies'].length)
                manipulateHtml(response['html'], text, location, response['companies'].length)

            },
            fail: function(jqXHR, textStatus, errorThrown) {    
                console.log('error');
                console.log(errorThrown);
            }
        });
    }

    //Not needed because no input for branch for the moment
    //function manipulateHtml(response, text, branch, location, count) {
    function manipulateHtml(response, text, location, count) {
        $('#searchresults').empty();
        $('#searchresults').append(response);

        $('#companies__results-count').empty();
        $('#companies__results-count').append(count + ((count == 1) ? ' Ergebnis' : ' Ergebnisse'));

        checkInputToManipulate($('#companies__searchparams-text'), text);
        //Not needed because no input for branch for the moment
        //checkInputToManipulate($('#companies__searchparams-branch'), branch);
        checkInputToManipulate($('#companies__searchparams-location'), location);
    }

    function checkInputToManipulate(obj, text) {
        obj.empty();
        if(text != '' && text != null) {
            obj.removeClass('companies__searchparams-item--no-margin');
            obj.append('# ' + text);
        } else {
            obj.addClass('companies__searchparams-item--no-margin');
        }
    }

    function geoCodePLZ(address) {
        geocoder.geocode( { 'address': address}, function(results, status) {
            plzLat = results[0].geometry.location.lat();
            plzLng = results[0].geometry.location.lng();
            map.setCenter(results[0].geometry.location);
            addressToMarker(plzLat, plzLng);
        });
    }

    function addressToMarker(lat, lng) {
        var marker = new google.maps.Marker({
            map: map,
            position: { lat: lat, lng: lng },
            icon: vars.images + '/PIN_sm.png',
        });
        markers.push(marker);
    }

    function setMapOnAll(map) {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(map);
        }
      }

    function deleteMarkers() {
        setMapOnAll(null);
        markers = [];
    }
});