
const texts = [];
$('.typewriter__data').each(function() {
    texts.push($(this).data('text'));
});

new Typewriter('#typewriter', {
    strings: texts,
    autoStart: true,
    loop: true,
    pauseFor: 1000,
});

