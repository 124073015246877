var clickEl = $("#searchCompany");
var valueEl = $("#searchCompanyText");
var val = '';

valueEl.focusout(function() {
    val = valueEl.val();
});

clickEl.on("click", function(event) {
    dataLayer.push({
        'event': 'search',
        'searchtext': val,
    });

    // gtag('send', {
    //     hitType: 'event',
    //     eventCategory: 'Search',
    //     eventAction: 'search',
    //     eventLabel: val,
    //   });

    //   gtag('send', 'event', {
    //     'eventCategory': 'Suche',
    //     'eventAction': 'Formular',
    //     'eventValue': val
    //   });

});


