var Towa = jQuery.extend({}, Towa || {});

;(function ($, $sc) {
    'use strict';

    /**
     * Define Config vars (vars that are used often)
     */
    Towa.Config = {
        $window: $(window),
        $html: $('html'),
        modulePaths: (typeof(vars) === 'undefined') ? '../../dist/js/modules/' : vars.themePath
    };

    /**
     * Base Functions
     */
    Towa.Common = {
        init: function () {
            console.log();
            //this.breakpoints();
            //this.touchEvents();
            //this.resize();
        },
        /**
         * Touchevent Detection with Modernizr
         */
        touchEvents: function () {
            Towa.Config.isTouch = (Modernizr.touchevents || Modernizr.pointerevents);
        },
        /**
         * Breakpoint Detection with Modernizr
         */
        breakpoints: function () {
            Towa.Config.isMobile = (Modernizr.mq('only screen and (max-width: 768px)'));
        },
        /**
         * Resize Function
         */
        resize: function () {
            Towa.Config.windowWidth = window.innerWidth;

            $.debounce(250, Towa.Config.$window.on('resize', function () {
                if (window.innerWidth !== Towa.Config.windowWidth) {
                    Towa.Config.windowWidth = window.innerWidth;
                    Towa.Common.breakpoints();
                }
            }));
        }
    };

    /**
     * Module Integration
     */
    Towa.Modules = {
        //add module name that is also classname
        allModules: ['slider', 'video-player', 'selectbox', 'accordeon', 'tab', 'firefighters', 'loadmore', 'comparisonnew'],
        /**
         * Lazyload Modules: add module name that is also classname
         */
        init: function () {
            //console.log('Modules');
            this.loadModules();

            //this.moduleOne();
        },
        //load all Modules in modules folder asynchronous
        loadModules: function () {
            //load all Modules in modules folder asynchronous
            for (var i = 0; i < Towa.Modules.allModules.length; i++) {
                var moduleName = Towa.Modules.allModules[i],
                    $module = $('.' + moduleName);

                if ($module.length > 0) {
                    Towa.Config.script(moduleName + '/' + moduleName);
                }
            }
        },
        /**
         * Integrate Modules in global.js
         */
        moduleOne: (function () {
            var _ = {},
                self = {};

            _.priv = "Privat";

            self.init = function () {
                //console.log('ModuleOne');
            };

            self.init();

            return self;
        })()
    };

    //Towa.Config.script = $sc;
    // Towa.Config.script.path(Towa.Config.modulePaths);

    Towa.Common.init();
    //Towa.Modules.init();

})(jQuery);

var script = document.createElement('script');
script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBOz05OuLpmdw2oSTXx_hEXPDqWoqzaTW4&callback=initMap';
script.async = true;
var map = '';
var markers = [];

// var script = document.createElement('script');
// script.src = 'https://unpkg.com/typewriter-effect@latest/dist/core.js';