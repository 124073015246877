let timebetween = 0;
let count = null;
$(document).ready(function () {
    
    $('#searchCompanyLocation').on('input', function() {

        clearTimeout(count);

        //start timeout
        count = setTimeout(function(){
            var input = $('#searchCompanyLocation').val();
            var jsonData = {};
            jsonData['input'] = input;
        
            if (input.length > 2) {
                restRequest($('#searchCompanyLocation'),jsonData);
            }
        }, 200);
        
    });

    function restRequest($obj,JsonData) {
        if(!$obj.length)
            return;

        var input = JsonData['input'];

        $.ajax({
            url: vars.getLocation_rest,
            data:{ input: input },
            dataType: "json",
            method:'GET',
            success: function (response) {
                var html = '';

                // $('#searchCompanyLocation').val(response['res'][0].zip + ' ' + response['res'][0].city);

                $('#searchform__autocomplete-list').empty();
                response['res'].forEach(element => {
                    html += '<li class="searchform__autocomplete-list-item">' + element.zip + ' ' + element.city + '</li>';
                });
                $('#searchform__autocomplete-list').addClass('searchform__autocomplete-list--visible');

                $(html).appendTo('#searchform__autocomplete-list');

                $('#searchform__autocomplete-list li').on('click', function() {
                    $('#searchCompanyLocation').val($(this).text());
                    $('#searchform__autocomplete-list').removeClass('searchform__autocomplete-list--visible');
                });

                $(document).click(function(event) {
                    if (!$(event.target).is('#searchCompanyLocation')) {
                        $('#searchform__autocomplete-list').removeClass('searchform__autocomplete-list--visible');
                    } else {
                        $('#searchform__autocomplete-list').addClass('searchform__autocomplete-list--visible');
                    }
                });

                //Nav with keyboard
                var li = $('.searchform__autocomplete-list-item');
                var liSelected;
                var selectedItem = 0;
                $(window).keydown(function(e){
                    if(e.which === 40){
                        if(liSelected){
                            liSelected.removeClass('selected');
                            if(li.eq(selectedItem).length > 0){
                                liSelected = li.eq(selectedItem).addClass('selected');
                                li.eq(selectedItem).addClass('selected');
                                selectedItem ++;
                            }else{
                                liSelected = li.eq(0).addClass('selected');
                                selectedItem = 1;
                            }
                        }else{
                            liSelected = li.eq(selectedItem).addClass('selected');
                            selectedItem ++;
                        }
                        $('#searchCompanyLocation').val(liSelected.html());
                    }else if(e.which === 38){
                        if(liSelected){
                            liSelected.removeClass('selected');
                            if(selectedItem > 0){
                                selectedItem --;
                                liSelected = li.eq(selectedItem).addClass('selected');
                                li.eq(selectedItem).addClass('selected');
                            }else{
                                selectedItem = li.length - 1;
                                liSelected = li.eq(li.length - 1).addClass('selected');
                            }
                        }else{
                            selectedItem = li.length - 1;
                            liSelected = li.eq(selectedItem).addClass('selected');
                        }
                        $('#searchCompanyLocation').val(liSelected.html());
                    }
                });
                
            },
            fail: function(jqXHR, textStatus, errorThrown) {    
                console.log('error');
                console.log(errorThrown);
            }
        });
    }

});

