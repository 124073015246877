var clickEl = $(".contactform__label-checkbox").find(".wpcf7-list-item-label");
var clickLink = clickEl.find("a");
var clickChecker = false;

clickLink.on("click", function() {
    clickChecker = true;
});

clickEl.on("click", function() {
    if (clickChecker === true) {
        clickChecker = false;
    } else {
        $(this).toggleClass("wpcf7-list-item-label--checked");
    }
});